/* Farben:

Grün: #9fc539;
Blau: #0071b4;
Rot:  #dc2323;
Türkies: #0090d4;
Grau: #363636; *selbst definiert*
 */

/* raleway-regular - latin */
@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    src: url('../webfonts/raleway-v13-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Raleway'), local('Raleway-Regular'),
    url('../webfonts/raleway-v13-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('../webfonts/raleway-v13-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('../webfonts/raleway-v13-latin-regular.woff') format('woff'), /* Modern Browsers */ url('../webfonts/raleway-v13-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('../webfonts/raleway-v13-latin-regular.svg#Raleway') format('svg'); /* Legacy iOS */
}

/* raleway-700 - latin */
@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    src: url('../webfonts/raleway-v13-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Raleway Bold'), local('Raleway-Bold'),
    url('../webfonts/raleway-v13-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('../webfonts/raleway-v13-latin-700.woff2') format('woff2'), /* Super Modern Browsers */ url('../webfonts/raleway-v13-latin-700.woff') format('woff'), /* Modern Browsers */ url('../webfonts/raleway-v13-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */ url('../webfonts/raleway-v13-latin-700.svg#Raleway') format('svg'); /* Legacy iOS */
}

body, html{
    height:100%;
}

h1 {
    font-size: 2rem;
}

h2 {
    font-size: 1.8rem;
}

h3 {
    font-size: 1.6rem;
}

h4 {
    font-size: 1.4rem;
}

h5 {
    font-size: 1.2rem;
    font-weight: bold;
}

h6 {
    font-size: 1rem;
    font-weight: bold;
}

hr {
    margin: 40px 0;
}

#userInfo {
    position: absolute;
    font-size: 0.7em;
    top: 0em;
    right: 1.5em;
    z-index: 555;
}

#userInfo em {
    color: #999;
}

#Slider .container {
    background-color: rgba(0, 113, 180, 0.7);
}

.languages {
    display: none;
}

.shadow,
.shadowBox {
    -webkit-box-shadow: 0 0 3px 0 #aaa;
    box-shadow: 0 0 3px 0 #aaa;
}

.radius {
    border-radius: 5px;
}

.shadowBox {
    overflow: hidden;
    position: relative;
    margin: 0 0 2em 0;
    box-sizing: border-box;
    border-radius: 5px;

    /*padding: 1.25em; */
    float: left;
    min-height: 250px !important;
    background-color: #fff;
}

.Grün {
    color: #9fc539 !important;
}

.Blau {
    color: #0071b4 !important;
}

.Rot {
    color: #dc2323 !important;
}

.Türkies {
    color: #0090d4 !important;
}

.Grau {
    color: #363636 !important;
}


.bgGrün {
    background-color: #9fc539 !important;
    color: #ffffff !important;
}

.bgBlau {
    background-color: #0071b4 !important;
    color: #ffffff !important;
}

.bgRot {
    background-color: #dc2323 !important;
    color: #ffffff !important;
}

.bgGrau, .bgGrau p {
    background: #eee !important;
    color: #363636 !important;
}

.bgDGrau, .bgDGrau p {
    background: #363636 !important;
    color: #fff !important;
}

.bgGrün, .bgGrün p,
.bgBlau, .bgBlau p,
.bgRot, .bgRot p {
    color: #ffffff !important;
}

.grad {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#0071b4+0,0090d4+50,9fc539+100 */
    background: #0071b4; /* Old browsers */
    background: -moz-linear-gradient(45deg, #0071b4 0%, #0090d4 50%, #9fc539 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, #0071b4 0%, #0090d4 50%, #9fc539 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, #0071b4 0%, #0090d4 50%, #9fc539 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0071b4', endColorstr='#9fc539', GradientType=1); /* IE6-9 fallback on horizontal gradient */
    color: #ffffff !important;
    font-weight: bold;
}

.grad, .grad p {
    color: #ffffff !important;
    font-weight: bold;
}

.vorteil {
    margin: 0 !important;
    font-size: 1em;
    border-collapse: collapse;
    padding: 0.5em;
    display: inline-block;
}

.vorteil p {
    margin-bottom: 1.5em !important;
}

.vorteil .bild {
    width: 100%;
    border-radius: 5px;
    margin-bottom: 10px;
    height: 100px !important;
    max-height: 100px !important;
    padding: 20px;
    background-color: #ffffff;
}

.vorteil .firmenLogo {
    width: 100%;
    height: 100%;

    background-size: contain !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
}

.vorteil .shadowBox {
    margin-bottom: 0 !important;
    min-width: 100% !important;
    overflow: hidden;
}

.vorteil:hover {
    cursor: pointer;
}
.vorteil h4 {
    font-size: 1.3em;
}

.vorteilDetail img + h5 {
    margin-top: 1.5em;
}

.kacheln p {
    margin: 0 !important;
}

.fullImg {
    padding: 0 !important;
    /*margin: 0 1em 2em 1em;*/
}

.fullImg .bild {
    width: 100%;
    min-height: 100px;
    background-size: cover;
    background-position: center;
    margin-bottom: 10px;
}

.fullImg h2 {
    font-size: 1.5em;
}

.fullImg h2, .fullImg p {
    margin: 15px;
}

.boxMargin {
    margin: 0 1em 2em 1em;
}

.navbar {
    margin-bottom: 70px;
    position: sticky;
    top: 0px;
    z-index: 500;
}

.navbar-nav a {
    color: #0071b4;
    padding: 0.41667em 1.75em;
    margin: 0 0.33333em;
    border-radius: 5px;
    text-decoration: none;
    -webkit-transition: all 400ms ease-in-out;
    -moz-transition: all 400ms ease-in-out;
    -o-transition: all 400ms ease-in-out;
    transition: all 400ms ease-in-out;
}

.navbar-nav a:hover {
    text-decoration: none;
    background-color: #0071b4;
    color: #ffffff;
}

.navbar-nav a.active:hover {
    background-color: #9fc539;
    color: #ffffff;
}

.navbar-nav a.active {
    background-color: #0071b4;
    color: #fff;
}

.laenderInfo {
    margin-top: 20px;
}

.laenderInfo span {
    margin-right: 1em;
    font-weight: bold;
}

.laenderInfo i {
    margin-right: 0.5em;
}

.laenderInfo p {
    margin-bottom: 0;
}

.laenderInfo em {
    margin-bottom: 20px;
    display: block;
}

.laenderInfo .linkBox {
    cursor: pointer;
}

.laenderInfo .shadowBox {
    padding-top: 20px;
    padding-bottom: 20px;
}

/* ======================= Ende BGL ======================= */
html, body {
    height: 100%;
}

body {
    margin: 0;
    background-color: #f9f9f9;
    font-family: 'Raleway', Helvetica, Arial, Geneva, sans-serif;
}


h1, h2, h3, h4, .nav {
    text-transform: uppercase;
    font-weight: 700;
}

html, body, p, td, li {
    color: #3e3e3e;
}

table, .table {
    background-color: #ffffff !important;
    -webkit-box-shadow: 0 4px 4px 1px rgba(0, 0, 0, 0.13);
    -moz-box-shadow: 0 4px 4px 1px rgba(0, 0, 0, 0.13);
    box-shadow: 0 4px 4px 1px rgba(0, 0, 0, 0.13);
    border: solid 5px #ffffff;
}

thead tr {
    border-bottom: solid 4px #ffffff !important;
}

tr {
    border-bottom: solid 1px #e3e3e3 !important;
}

th {
    background-color: #F5F5F5 !important;
    padding: 5px;
}

td {
    padding: 5px;
}

input[type="text"] {
    /* background-color: #ffffff !important; */
}

input[type="radio"] {
    -ms-transform: scale(1.9);
    -webkit-transform: scale(1.9);
    transform: scale(1.9);
    margin-right: 20px;
}

#logo_big {
    height: 100px;
}

#logo_big.small {
    height: 50px !important;
}

#content{
    height:auto;
    min-height:100%;
    padding-bottom: 140px;
}

#Footer {
    background-color: #eeeeee !important;
    border-top: solid 1px #8C8C8C;
    margin: -140px 0 0 0 !important;
    padding: 0 !important;
    clear: both;
    flex: content;
    min-height: 140px;
}

#Footer div{
    background-color: rot !important;
    border-collapse: collapse;
    padding-top: 20px;
    padding-bottom: 20px;
}

#Footer h6 {
    font-weight: bold;
}

#Footer p {
    font-size: 1em;
    margin-bottom: 0;
}

#Footer a {
    color: #000000;
}

#Footer a:hover {
    color: #0071b4;
    text-decoration: none;
}

#Footer li {
    list-style-image: none;
    list-style-type: none;
    list-style-position: outside;
    margin-left: -40px !important;
    margin-bottom: 15px;
    font-weight: 500;
}

#Footer .btn, #Footer .btn-secondary {
    min-width: 170px;
}
#Footer .links{
    padding-left: 40px;
}
#Footer .rechts {
    text-align: right!important;
}



#Footer .Trenner,
#Footer .btn {
    margin: 0 10px;
}


.glow {
    -webkit-box-shadow: 0 0 3px 3px rgba(46, 120, 190, 1);
    -moz-box-shadow: 0 0 3px 3px rgba(46, 120, 190, 1);
    box-shadow: 0 0 3px 3px rgba(46, 120, 190, 1);
}

.darken {
    background-color: rgba(0, 0, 0, 0.2);
}

/* Akkordeon */
.accTitle, .accStaus, .accService {
    margin: 0;
    padding: 0;
}

.accTitle {
    font-size: 1.6rem;
    /*font-weight: bold;*/
}

.accTitle i {
    padding: 0 10px;
    min-width: 80px;
    width: 80px;
    text-align: center;
    overflow: hidden;
}

.accStaus, .accService {
    font-size: 1.2em;
}

.accService {
    padding-right: 10px;
}

/* Reiter / Tabs */
.nav-item.border {
    border: 1px solid #ECEFF3;
    border-radius: 4px;
    margin-right: 5px;
    background-color: #ffffff;
    font-size: 1.4em;
}

.nav-item.border .active {
    background-color: #ECEFF3 !important;
}

.padding10 {
    padding: 10px !important;
}


/* -================================= Korrektur Bootstrap ================================= */

.navbar {
    background-color: #ffffff;
    -webkit-box-shadow: 0 25px 30px -15px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 0 25px 30px -15px rgba(0, 0, 0, 0.6);
    box-shadow: 0 25px 30px -15px rgba(0, 0, 0, 0.6);
}

.alert-info {
    background-color: #ecf4ff !important;
}

.alert-info i {
    margin-right: 20px;
}

.btn-primary,
.btn-outline-primary,
.btn-secondary,
.btn-success {
    text-transform: uppercase;
    font-size: 1rem;
}


.btn-primary {
    background-color: #0071b4 !important;
    border: none;

}

.btn-primary:hover {
    background-color: #9fc539 !important;
}

.btn-outline-primary {
    border-color: #0071B4;
    color: #0071B4;
}

.btn-outline-primary:hover {
    background-color: #0071B4;
    color: #ffffff;
}

.btn-secondary {
    background-color: #9fc539 !important;
    color: #000000;
    border: none;
}

.btn-secondary:hover {
    background-color: #0071b4 !important;
}

.btn-success {
    background: #0071b4; /* Old browsers */
    background: -moz-linear-gradient(45deg, #0071b4 0%, #0090d4 50%, #9fc539 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, #0071b4 0%, #0090d4 50%, #9fc539 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, #0071b4 0%, #0090d4 50%, #9fc539 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0071b4', endColorstr='#9fc539', GradientType=1); /* IE6-9 fallback on horizontal gradient */
    border-color: #0071B4;
    color: #ffffff !important;
}

.btn-success:hover {
    background: none #9fc539 !important;
}

#navcol-1 {
    position: absolute;
    right: 0;
}

.modal {
    z-index: 99999;
    background-color: rgba(0, 20, 33, 0.6);
}

.modal-dialog {
    width: 90% !important;
}

/* -================================= responsivity ================================= */

@media (min-width: 575px) {}

@media (max-width: 767px) {
    #Footer .rechts {
        text-align: center !important;
    }

    #navcol-1 {
        top: 70px;
        text-align: left !important;
        background-color: rgba(255, 255, 255, 0.8);
    }

    #navcol-1 ul {
        text-align: left !important;
        margin-right: 10px;
    }

    #navcol-1 li {
        margin-top: 10px;
    }

    #navcol-1 li a {
        padding: 5px 20px !important;
    }
}

@media (min-width: 768px) {
    .vorteil_alt {
        margin-left: 1em !important;
    }
}

@media (min-width: 992px) {}

@media (min-width: 1200px) {}