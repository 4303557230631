/* red cross checkbox */
input[type=checkbox].css-checkbox-red {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

input[type=checkbox].css-checkbox-red + label.css-label {
    padding-left: 20px;
    height: 15px;
    display: inline-block;
    line-height: 15px;
    background-repeat: no-repeat;
    background-position: 0 0;
    font-size: 15px;
    /* vertical-align: middle; */
    cursor: pointer;
}

input[type=checkbox].css-checkbox-red:checked + label.css-label {
    background-position: 0 -15px;

}

.css-label {
    background-image: url(http://csscheckbox.com/checkboxes/lite-x-red.png);
}

/* green checkbox */
input[type=checkbox].css-checkbox-green {
    position: absolute;
    z-index: -1000;
    left: -1000px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

input[type=checkbox].css-checkbox-green + label.css-label-green {
    padding-left: 45px;
    height: 40px;
    display: inline-block;
    line-height: 40px;
    background-repeat: no-repeat;
    background-position: 0 0;
    font-size: 2em;
    vertical-align: middle;
    cursor: pointer;

}

input[type=checkbox].css-checkbox-green:checked + label.css-label-green {
    background-position: 0 -40px;
}

label.css-label-green {
    background-image: url(http://csscheckbox.com/checkboxes/u/csscheckbox_1795311a987e53018946f8d62574d70e.png);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}