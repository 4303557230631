.article-list {
  color:#313437;
}

.article-list p {
  color:#7d8285;
}

.article-list h2 {
  font-weight:bold;
  margin-bottom:40px;
  padding-top:40px;
  color:inherit;
}

@media (max-width:767px) {
  .article-list h2 {
    margin-bottom:25px;
    padding-top:25px;
    font-size:24px;
  }
}

.article-list .intro {
  font-size:16px;
  max-width:500px;
  margin:0 auto;
}

.article-list .intro p {
  margin-bottom:0;
}

.article-list .articles {
  padding-bottom:40px;
}

.article-list .item {
  padding-top:50px;
  min-height:425px;
  text-align:center;
  background-color: #ffffff;
}

.article-list .item .name {
  font-weight:bold;
  font-size:16px;
  margin-top:20px;
  color:inherit;
}

.article-list .item .description {
  font-size:14px;
  margin-top:15px;
  margin-bottom:0;
}

.article-list .item .action {
  font-size:24px;
  width:24px;
  margin:22px auto 0;
  line-height:1;
  display:block;
  color:#4f86c3;
  opacity:0.85;
  transition:opacity 0.2s;
  text-decoration:none;
}

.article-list .item .action:hover {
  opacity:1;
}

